.progressVisualFull {
  display: flex;
  /* change the value if you want different thickness */
  height: 10px;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s;
}

.progressVisualPart {
  /* Number of the seconds for complete animation */
  transition: width 2s;
}

.progressLabel {
  font-weight: bold;
  letter-spacing: 5px;
}
